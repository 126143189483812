



























































































import { Component, Vue } from 'vue-property-decorator';
import MyNav from '../../components/Navbar.vue';
import axios from 'axios';
import slugify from 'slugify';
import Api  from '../../router/api';

@Component({
  components: {
    MyNav
  }
})
export default class Home extends Vue {
  public showDialog = false;
  public list: any = null
  public loading = false;
  public step = 0;
  public language = 'pt';
  public email = '';
  public error = false;
  public edit: any = undefined;
  public boxMsg = '';
  public obj: any = this.reset(); 
  public user = '';
  public extras = []

  public reset() {
    return {    
      _id: null,
      name: '',
      slug: '',
      client: this.$route.params.code,
      bgLogin : '',
      bgLive : '',
      typeLive : null,
      hasPrize: false,
      hasQuestion: false,
      question: '',
      typeValidation : null,
      typeIdioma : null,
      linkExternal : null,
      addInputs : [],
      extraInputs : null,
      validationStrings : null,
      languages : {
        pt:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        },
        en:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        },
        es:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        }
      },
      addInput: null,
      chatLink: null
    }
  }

  public async entrar(){
    let count = 0
    if (this.email == '') return
    if (this.obj.languages.en.check) count++;
    if (this.obj.languages.pt.check) count++;
    if (this.obj.languages.es.check) count++;
    if (count<2) {
      await this.finish(); return;
    }
    this.step = 1;
  }

  public async finish(){
    this.loading = true;    
    const obj = await axios.post(`${process.env.VUE_APP_API}createUser`,{
      live: this.obj._id,
      email: this.email,
      languages: this.language,
      addInputs: this.extras
    },{headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json"
      }})
    .then((d) => {return {error: false, body : d.data}; }).catch((e) => { return {error:true, body:null}})
    if (!obj.error){
      localStorage.setItem(`${this.obj._id}-user-${this.$route.params.live}`,obj.body._id);
      localStorage.setItem(`email-${this.$route.params.live}`,obj.body.email);
      this.$router.push({ name: 'lives-interna', params: { client: this.$route.params.client, live: this.$route.params.live }, query:{ lang: this.language} })
      this.loading = false;
    } else {
      this.error = true;
      this.boxMsg = 'Usuário não autorizado.'
      this.loading = false;    
    }
  }

  public async atualiza(){
    this.list = null
    this.obj = this.reset();
    this.loading = true;    
    const obj = await axios.get(process.env.VUE_APP_API+`live-read?client=${this.$route.params.client}&slug=${this.$route.params.live}`)
    .then((d) => {return {error: false, body : d.data}; })    
    if (!obj.error){
      this.obj = obj.body;
      document.title = `${obj.body.name} - ${obj.body.client.name}`;  
    } else {
      this.error = true;
      this.boxMsg = 'Usuário não autorizado.'
    }
    this.loading = false;
  }

  public async created(){
    document.title = 'Carregando....';  
    this.loading = true;
    await this.atualiza();
    this.loading = false;
  }


}
